export const VOD_ENDPOINT = "qalbox-admin-media-transcode/v1/";
export const VOD_METADATA_LOADING_MSG = "Loading VOD Metadata ....";
export const VOD_METADATA_RECORD_DELETING_MSG =
  "Deleting VOD Metadata in progress !!!";
export const TOASTER_AUTO_CLOSE_DURATION = 2000;

export const languageMap = {
  ENG: { language: "en", name: "English" },
  IND: { language: "id", name: "Indonesian" },
  MSA: { language: "ms", name: "Malay" },
  FRA: { language: "fr", name: "French" },
  ARA: { language: "ar", name: "Arabic" },
  BEN: { language: "bn", name: "Bengali" },
  BOS: { language: "bs", name: "Bosnian" },
  DUT: { language: "nl", name: "Dutch" },
  HIN: { language: "hi", name: "Hindi" },
  KUR: { language: "ku", name: "Kurdish" },
  FAS: { language: "fa", name: "Persian" },
  TGL: { language: "tl", name: "Tagalog" },
  THA: { language: "th", name: "Thai" },
  TUR: { language: "tr", name: "Turkish" },
  GER: { language: "de", name: "German" },
  KAZ: { language: "kk", name: "Kazakh" },
};

export const languageMapISO639_1 = {
  en: { language: "en", name: "English" },
  id: { language: "id", name: "Indonesian" },
  ms: { language: "ms", name: "Malay" },
  fr: { language: "fr", name: "French" },
  ar: { language: "ar", name: "Arabic" },
  bn: { language: "bn", name: "Bengali" },
  bs: { language: "bs", name: "Bosnian" },
  nl: { language: "nl", name: "Dutch" },
  hi: { language: "hi", name: "Hindi" },
  ku: { language: "ku", name: "Kurdish" },
  fa: { language: "fa", name: "Persian" },
  tl: { language: "tl", name: "Tagalog" },
  th: { language: "th", name: "Thai" },
  tr: { language: "tr", name: "Turkish" },
  de: { language: "de", name: "German" },
  kk: { language: "kk", name: "Kazakh" },
};

export const SONIC_BRANDING = {
  sonic_branding_pro_30: "sonic_branding_pro_30",
  sonic_branding_pro_hq_30: "sonic_branding_pro_hq_30",
  sonic_branding_pro_24: "sonic_branding_pro_24",
  sonic_branding_pro_hq_23976: "sonic_branding_pro_hq_23976",
  sonic_branding_pro_29976: "sonic_branding_pro_29976",
  sonic_branding_pro_hq_25: "sonic_branding_pro_hq_25",
  sonic_branding_pro_hq_24: "sonic_branding_pro_hq_24",
  sonic_branding_pro_23976: "sonic_branding_pro_23976",
  sonic_branding_pro_25: "sonic_branding_pro_25",
  branding_common: "branding_common",
  qb_closing_cta: "qb_closing_cta",
  null: null
};
